@mixin buttonStyle {
  text-decoration: none;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 35px;
  border-radius: 7px;
  &.primary {
    background: $blue300;
    color: white;

    &:hover {
      background: darken($blue300, 10%);
    }
  }
}

button {
  @include buttonStyle();
  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

.buttonStyle {
  @include buttonStyle();
}

.link-btn {
  background: none;
  border: none;
  font-size: 14px;
  padding: 0px;
  color: $blue300;
  text-decoration: underline;
}
