@use 'sass:map';
.heading-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  p {
    margin: 0px;
  }
  h1 {
    font-size: 24px;
    color: #000;
    font-weight: normal;
    margin-bottom: 5px;
  }

  .balance-amt {
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
    :first-of-type {
      color: $blue300;
    }
    display: flex;
    justify-content: space-between;
  }
  .heading-container-left-col {
    max-width: 31%;
  }
  .heading-container-right-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    div {
      width: 300px;
      height: 80px;
    }
    p {
      font-size: 22px;
      display: flex;
      justify-content: space-between;
      width: 81%;
      &.text__blue {
        color: $blue300;
      }
    }
  }
}
.payment-info-container {
  &.invoice {
    width: 32%;
  }
  .error-banner {
    background: $error;
    color: white;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    display: flex;
    svg {
      margin: 0px 10px;
    }
  }
}
.payment-section {
  background: lighten($blue300, 45%);
  border-radius: 8px;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 22px;
    text-align: center;
    font-weight: 500;
  }
  .text-input {
    width: 100%;
    border: 1px solid #dcdee2;
    margin-top: 15px;
    margin: 5px 0px;
    font-size: 16px;
    border-radius: 3px;
    background: white;
    &:focus {
      border: 2px solid #1e74b6;
    }
    fieldset {
      border: none;
    }
    input {
      padding: 14px 14px;
      padding-left: 10px;
      color: #666;
    }
    .Mui-error {
      border: 1px solid red;
    }
  }
  button.primary {
    width: 90%;
    margin-top: 10px;
    font-weight: normal;
    border-radius: 4px;
    &.disabled {
      filter: opacity(25%);
      &:hover {
        background: $blue300;
        cursor: default;
      }
    }
  }
  .state-zip-row {
    display: flex;
    justify-content: space-between;
    > div {
      width: 48%;
    }
    .MuiSelect-select {
      padding: 14px 14px;
    }
  }
  .address-wrapper {
    .text-input {
      margin-top: 0px;
    }
    > div:not(:first-of-type) {
      margin-top: 20px;
    }
  }
  .address-container {
    margin-top: 25px;
    .error {
      color: red;
      border-bottom: 2px solid red;
    }
    .Mui-selected {
      //overrides selected tab turning blue rule
      &.error {
        color: red;
        border-bottom: 2px solid red;
      }
    }
  }
  .surcharge-totals {
    > div {
      margin: 5px 0px;
      color: $grey_700;
      width: 90%;
      display: flex;
      justify-content: space-between;
    }
  }
  .surcharge-text {
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
  }
}

.disclaimer {
  text-align: center;
  width: 85%;
  margin: auto;
  font-size: 14px;
  margin-top: 20px;
}

.disable-tokenizer {
  pointer-events: none;
}

.payment-body {
  display: flex;
  justify-content: space-between;
  h2 {
    margin-top: 0px;
    font-weight: 500;
  }
}
.payment-summary-container {
  width: 66%;
  border: 1px solid #00000054;
  padding: 30px 100px 20px;
  border-radius: 8px;
  background: white;
  height: fit-content;
  .summary-table {
    padding-left: 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      text-align: left;
      color: $grey_700;
      padding-bottom: 20px;
    }
    td {
      p {
        margin: 0px;
        color: #666;
        font-size: 14px;
        padding-bottom: 20px;
      }
    }
    tr:not(:first-of-type) {
      td {
        padding-top: 15px;
      }
    }
    .invoice-row {
      border-bottom: 1px solid #0000004d;
    }
  }

  .summary-header {
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
    h2 {
      margin: 0px;
    }
    button {
      margin-left: 30px;
    }
  }
}

.history-dialog-wrapper {
  .MuiPaper-root {
    padding: 20px;
    min-width: 750px;
    border-radius: 15px;
  }
  h3 {
    text-align: center;
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 20px;
    font-weight: 500;
    color: $grey_700;
  }

  .close-btn {
    display: flex;
    justify-content: flex-end;
    svg {
      font-size: 26px;
    }
  }

  .totals-summary {
    border: 1px solid lighten($blue300, 40%);
    padding: 20px;
    padding-bottom: 15px;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      span {
        font-size: 20px;
      }
      span:first-of-type {
        font-weight: 800;
        color: $blue300;
        margin-right: 15px;
      }
    }
  }

  table {
    border-spacing: 0px;
    width: 100%;
    margin-bottom: 40px;
    th {
      color: $grey_700;
      border-bottom: 1px solid #616161;
      padding-bottom: 20px;
    }
    td {
      padding: 20px 0px;
      border-bottom: 1px solid #616161;
    }
  }
  .body-wrapper {
    padding: 0px 30px;
  }
  .copy-btn {
    color: $blue300;
  }
}

.summary-totals {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  > div {
    text-align: right;
    padding-right: 35px;
  }
  .summary-col-wrapper {
    display: flex;
    justify-content: space-between;
  }
  span:first-of-type {
    margin-right: 15px;
  }
  .total-row {
    font-size: 20px;
    margin-top: 20px;
    color: $blue300;
    font-weight: bold;
    span:first-of-type {
      margin-right: 3px;
    }
  }
}

//Success Screen
.invoice-success-wrapper {
  display: flex;
  justify-content: center;
  h1 {
    font-size: 35px;
    text-align: center;
  }
  .payment-information {
    border: 1px solid lighten($blue300, 40%);
    padding: 20px;
    padding-bottom: 15px;
    border-radius: 5px;
    width: 85%;
    margin: auto;
    margin-bottom: 15px;
    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    span {
      font-size: 20px;
    }
    span:first-of-type {
      font-size: 18px;
      font-weight: 800;
      color: $blue300;
    }
  }
  .image-wrapper {
    display: flex;
    justify-content: center;
  }
  .help-text {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .invoice-information {
    border-radius: 4px;
    display: flex;
    align-items: center;
    background: lighten($blue300, 45%);
    width: fit-content;
    padding-right: 20px;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 30px;
    div:first-of-type {
      background: lighten($blue300, 40%);
      padding-right: 5px;
      border-radius: 4px;
      padding: 10px;
      padding-right: 10px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 700;
    }
  }

  button.primary {
    width: 100%;
    border-radius: 4px;
    padding: 15px 35px;
  }
}

.invoice-error-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 30px;
    width: 70%;
    text-align: center;
  }
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: map.get($container-max-widths, 'md')) {
  .heading-container {
    flex-direction: column;
    .heading-container-left-col {
      max-width: initial;
    }
    .heading-container-right-col {
      align-items: flex-start;
      > div {
        display: none;
      }
      p {
        width: 100%;
      }
    }
  }
  .payment-body {
    flex-direction: column-reverse;
    .payment-info-container,
    .payment-summary-container {
      width: 100%;
    }
    .payment-summary-container {
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .history-dialog-wrapper {
    .MuiPaper-root {
      min-width: initial;
    }

    .totals-summary {
      flex-direction: column;
      div {
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: map.get($container-max-widths, 'sm')) {
  .history-dialog-wrapper {
    .body-wrapper {
      padding: 0px 10px;
    }
  }
}
