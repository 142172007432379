footer {
  margin-top: auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: transparent;
  margin-top: 40px;
  padding-bottom: 30px;
  .footer-text {
    margin-bottom: 15px;
    a {
      text-decoration: none;
      color: $grey_700;
      background: none;
      font-size: 14px;
      font-weight: 500;
    }
    span {
      color: $grey_700;
      font-size: 14px;
      margin-right: 4px;
    }
  }

  img {
    max-width: 100px;
    margin: 0px 15px;
  }
  .powered-by-block {
    display: flex;
    align-items: flex-start;
    span {
      font-size: 22px;
      font-weight: 500;
      color: $grey_700;
    }
  }
}
.branding-footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
    margin-bottom: -10px;
  }
}
