@use 'sass:map';
.landing-container {
  display: grid;
  grid-template-columns: 50% 50%;
  .heading-block {
    grid-row: 1;
  }

  .invoice-example-block {
    grid-row: 1 / 4;
    img {
      width: 100%;
    }
  }

  .detail-block {
    grid-row: 2 / 5;
  }
  .two-picture-block {
    margin-top: -12%;
    display: flex;
    img {
      &:first-of-type {
        align-self: baseline;
        width: 250px;
      }

      width: 120px;
    }
  }

  .detail-block {
    p {
      font-size: 22px;
      font-weight: 100;
      margin-bottom: 0px;
    }
    ul {
      margin-bottom: 40px;
      list-style: none;
      padding: 0px;
      li {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    p,
    li {
      color: $dark-text-blue;
    }
  }
  .learn-more-mobile {
    display: none;
  }
}

.body-background {
  background-image: url('../../images/iqpro-background-gradient.png');
  background-size: cover;
  background-repeat: round;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: map.get($container-max-widths, 'md')) {
  .landing-container {
    grid-template-columns: 100%;
    .invoice-example-block {
      grid-row: 2;
    }
    .detail-block {
      grid-row: 3;
    }
    .learn-more-desktop {
      display: none;
    }
    .learn-more-mobile {
      display: flex;
      justify-content: center;
    }
    .two-picture-block {
      margin-top: -5%;
      align-items: baseline;
      justify-content: flex-end;
      // ul {
      //     margin-bottom: 0px;
      // }
      // img {
      //     &:first-of-type {
      //         width: 120px;
      //     }

      // }
    }
  }
}

@media only screen and (max-width: map.get($container-max-widths, 'sm')) {
  .landing-container {
    .learn-more-mobile {
      margin-top: 20px;
    }
    .two-picture-block {
      margin-top: -25%;
      align-items: baseline;
      justify-content: flex-end;
    }
    .detail-block {
      li:last-of-type {
        width: 54%;
      }
    }
  }
}
