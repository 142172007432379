.ach-form {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  .text-input.MuiTextField-root {
    margin-top: 0px;
  }
  .text-input {
    input {
      padding: 12px 14px;
    }
  }
  .MuiInputBase-root.text-input {
    background: white;
    height: 50px;
    margin-top: 0px;
    color: #666;
  }
  .MuiFormHelperText-root {
    background-color: #d4effa;
    border-style: none;
    text-align: center;
    margin: 0px;
    &.Mui-error {
      border-color: transparent !important;
    }
  }
}

.tokenEx-card-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: "Plus Jakarta Sans", Roboto, Helvetica, Arial, sans-serif;

  .card {
    width: 100%;
    margin-bottom: 5px;
  }
  .card-row {
    color: #666;
    font-weight: 400;
    display: block;
    height: 50px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    background-color: #fff;
    outline: 0;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
    &.exp {
      padding: 5px 0px;
      padding-left: 10px;
      width: 100%;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgb(186, 187, 189);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgb(186, 187, 189);
      }
    }
  }
  .card-wrapper {
    display: flex;
    align-items: center;
  }
  .form-label {
    color: #1b1b1b;
    font-size: 14px;
    margin-left: 5px;
    display: block;
    margin-bottom: 5px;
  }

  .invalid {
    .card-row {
      border-color: red;
    }
  }
  .secondary {
    width: 49%;
  }
}
