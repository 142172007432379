@use 'sass:map';
.simplepay {
  width: 100%;
  color: var(--branding-typography-bg);
  .main-row {
    display: flex;
    justify-content: space-between;
  }
  .left-column {
    width: 32%;
    margin-right: 4%;
  }
  .right-column {
    width: 64%;
  }
  .country-city-state-zip-row {
    display: flex;
    justify-content: space-between;
    > div {
      width: 23.5%;
    }
    .MuiSelect-select {
      padding: 7.5px 10px;
    }
  }
  .two-column-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    > div {
      width: 100%;
      &:first-child {
        margin-right: 2%;
      }
      &:last-child {
        margin-right: 0; //overrides right margin if only one in row
      }
    }
  }
  .payment-section {
    margin-bottom: 12px;
    color: var(--branding-typography-payment);
    padding-top: 4px;
    h2 {
      margin: 8px;
    }
    .text-input {
      input {
        padding: 7.5px 10px;
      }
    }
  }
  .tokenEx-card-wrapper .card-row {
    height: 38px;
  }
  .heading-container {
    display: flex;
    justify-content: space-between;
    h1 {
      color: var(--branding-typography-bg);
    }
    .heading-container-left-col {
      width: 32%;
      margin-right: 4%;
    }
    .heading-container-right-col {
      width: 64%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .heading-container-centered-header {
    display: flex;
    justify-content: center;
    .heading-container-left-col {
      display: none;
    }
    .heading-container-right-col {
      display: flex;
      justify-content: center;
    }
    h1 {
      font-size: 24px;
      color: var(--branding-typography-bg);
      font-weight: normal;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .message-banner {
    background: #d4effa;
    border-color: #5FC3ED;
    color: #5FC3ED;
    font-weight: 700;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    svg {
      margin: 0px 10px;
    }
    &.banner-type-success {
      background: #F6FFED;
      border-color: #B7E897;
      color: #52C41A;
    }
    &.banner-type-dangerous {
      background: #FFF1F0;
      border-color: #FFA39E;
      color: #FF4D4F;
    }
    &.banner-type-warning {
      background: #FFFBE6;
      border-color: #FFE096;
      color: #FAAD14;
    }
  }
  .email-receipt {
    display: flex;
    justify-content: center;
    margin-top: 4px;
  }
  .amountWrapper {
    display: flex;
    > div:first-child {
      width: 22%;
      > div {
        
      background-color: #FAFAFA;
        border-right: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        > div {
          padding: 14px 6px;
        }
      }
    }
    > div:last-child {
      width: 78%;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  #CvvTextbox > iframe {
    border-radius: 4px;
  }
  .simple-success-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    .thanks-headline {
      font-size: 2.25rem;
      font-weight: 600;
    }
    .success-amount {
      font-size: 3.25rem;
      font-weight: 600;
      margin-top: -4px;
    }
    .transaction-id {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 24px;
    }
    button.primary {
      width: 65%;
      margin-top: 32px;
      font-weight: normal;
      border-radius: 4px;
      &.disabled {
        filter: opacity(25%);
        &:hover {
          background: $blue300;
          cursor: default;
        }
      }
    }
  }
  button.primary {
    &:hover {
      filter: brightness(90%);
    }
  }
  .right-column {
    .MuiInputBase-root {
      background-color: #FFF;
      &.Mui-error {
        opacity: 0.8;
      }
    }
  }
  .MuiInputBase-input {
    padding: 7.5px 10px;
  }
  .ach-form .MuiInputBase-root.text-input {
    height: auto;
  }
  .MuiSelect-select {
    display: flex;
    align-items: center;
  }
  p {
    &.disclaimer {
      margin-top: 0px;
    }
  }
  .payment-info-container .error-banner {
    margin: 5px auto;
  }
  @media only screen and (max-width: map.get($container-max-widths, 'xl')) {
    .left-column {
      width: 50%;
      margin-right: 4%;
    }
    .right-column {
      width: 50%;
    }
    .country-city-state-zip-row {
      flex-wrap: wrap;
      > div {
        width: 49%;
      }
    }
    .amountWrapper {
      display: flex;
      > div:first-child {
        width: 18%;
      }
      > div:last-child {
        width: 82%;
      }
    }
  }
  .requiredAsterisk {
    margin-left: 4px;
    color: var(--asterisk-color);
    font-size: 1.4rem;
    line-height: normal;
    height: 50%;
    position: relative;
    top: 3px;
  }
  .address-container {
    h3 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .additional-info-container {
    h3 {
      margin-top: 6px;
      margin-bottom: 0;
    }
  }
  .field-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    > label {
      display: flex;
      margin-bottom: 2px;
      height: 1.3rem;
    }
  }
  .payment-section-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-wrapper img {
    width: 25%;
  }
  @media only screen and (max-width: map.get($container-max-widths, 'lg')) {
    .main-row {
      flex-wrap: wrap;
    }
    .left-column {
      width: 100%;
      margin-right: 0%;
    }
    .right-column {
      width: 100%;
    }
    .country-city-state-zip-row {
      flex-wrap: wrap;
      > div {
        width: 49%;
      }
    }
    .amountWrapper {
      display: flex;
      > div:first-child {
        width: 14%;
      }
      > div:last-child {
        width: 86%;
      }
    }
  }  
  @media only screen and (max-width: map.get($container-max-widths, 'sm')) {
    .main-row {
      flex-direction: column-reverse;
      align-items: center;
    }
    .left-column {
      width: 100%;
      margin-right: 0px;
    }
    .right-column {
      width: 100%;
    }
    .country-city-state-zip-row {
      flex-direction: column;
      justify-content: space-between;
      > div {
        width: 100%;
      }
      .MuiSelect-select {
        padding: 16.5px 14px;
      }
    }
    .two-column-row {
      flex-direction: column;
      justify-content: space-between;
      > div {
        width: 100%;
      }
    }
    .amountWrapper {
      display: flex;
      > div:first-child {
        width: 27%;
      }
      > div:last-child {
        width: 73%;
      }
    }
    .payment-section {
      margin-top: 20px;
  }
  }
  @media only screen and (min-width: map.get($container-max-widths, 'md')) {
    .heading-container {
      margin-top: -58px;
    }
  }   
}
.dark-theme {
  .text-input, .MuiTextField-root {
    border-color: #dcdee2;
    background-color: #212121;
    .MuiInputBase-root, &.MuiInputBase-root {
      background-color: #212121;
      color: #eaebeb;
      }
    input {
      color: #eaebeb;
    }
  }
}

.body-background {
  background-color: var(--background-color, #FFF);
  background-image: var(--background-image, url('../../images/iqpro-background-gradient.png')); 
  &.dark-theme {
    background-color: var(--background-color, #121212);
    background-image: var(--background-image, url('../../images/iqpro-background-gradient.png'));
  }
}

footer.simpleFooter {
  .footer {
    padding-bottom: 12px;
  }
}
.heading.simpleHeader {
  padding-top: 12px;
}

//overrides layout.scss setting height & min-height to 100%, causing payment info tooltip to appear at the top of the screen
body > div.MuiTooltip-popper {
  height: auto;
  min-height: auto;
}