@use 'sass:map';
// pixels
$font_size_6: 6;
$font_size__8: 8;
$font_size_12: 12;
$font_size_14: 14;
$font_size_16: 16;
$font_size_18: 18;
$font_size_20: 20;
$font_size_22: 22;
$font_size_23: 23;
$font_size_24: 24;
$font_size_26: 26;
$font_size_28: 28;
$font_size_30: 30;
$font_size_32: 32;
$font_size_34: 34;
$font_size_500: 500;

// spacing usually odd sizes
$spacing_pt_5: 0.5;
$spacing_3: 3;
$spacing_4: 4;
$spacing_8: 8;
$spacing_32: 32;
$spacing_48: 48;

// component specific
$paper_mw: 936;

:export {
  font_size_6: $font_size_6;
  font_size__8: $font_size__8;
  font_size_12: $font_size_12;
  font_size_14: $font_size_14;
  font_size_16: $font_size_16;
  font_size_18: $font_size_18;
  font_size_20: $font_size_20;
  font_size_22: $font_size_22;
  font_size_23: $font_size_23;
  font_size_24: $font_size_24;
  font_size_26: $font_size_26;
  font_size_28: $font_size_28;
  font_size_30: $font_size_30;
  font_size_32: $font_size_32;
  font_size_34: $font_size_34;
  font_size_500: $font_size_500;

  // spacing
  spacing_pt_5: $spacing_pt_5;
  spacing_3: $spacing_3;
  spacing_4: $spacing_4;
  spacing_8: $spacing_8;
  spacing_32: $spacing_32;
  spacing_48: $spacing_48;

  // component specific
  paper_mw: $paper_mw;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

html,
body {
  min-height: 100%;
  height: 100%;
}

.app-wrapper,
.heading,
.footer {
  max-width: map.get($container-max-widths, 'xxl');
  padding: 0px 20px;
  margin: 0px auto;
  width: 100%;
}

body {
  > div {
    min-height: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: map.get($container-max-widths, 'md')) {
  body {
    background-repeat: inherit;
  }
}
