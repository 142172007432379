@import '../abstracts/colors.module.scss';

body {
  font-size: 18px;
}

// Typography
h1 {
  color: $dark-text-blue;
  font-weight: bold;
  font-size: 50px;
  line-height: normal;
  &.underline {
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 4px;
      width: 90px;
      background: #15304a;
      border-radius: 5px;
      position: absolute;
      bottom: -40px;
    }
  }
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 14px;
  }
  h1 {
    font-size: 30px;
    &.underline:after {
      content: none;
    }
  }
}
