@use 'sass:map';

.heading {
  padding-top: 50px;
  img {
    width: 150px;
  }
  &.centered-header {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    img {
      width: 275px;
    }
  }
}

@media only screen and (max-width: map.get($container-max-widths, 'sm')) {
  .heading {
    img {
      width: 200px;
    }
  }
}
