.dark-theme {
  &.body-background {
    background-image: url('../../images/dark-mode/iqpro-background-gradient-dark.png');
  }
  .heading-container {
    h1 {
      color: white;
    }
  }

  //
  // Landing
  .landing-container {
    h1 {
      color: white;
      &:after {
        background-color: white;
      }
    }
    .detail-block {
      p,
      ul,
      li {
        color: white;
      }
    }
  }
  //
  // Invoicing
  .payment-summary-container {
    background: #121212;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    table {
      th {
        color: white;
      }
      td {
        p {
          color: white;
        }
      }
      .invoice-row {
        border-bottom: 1px solid white;
      }
    }
  }

  .payment-section {
    background: #121212;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));

    .text-input {
      border-color: #dcdee2;
      background-color: #212121;
      input {
        color: #eaebeb;
      }
    }

    .MuiOutlinedInput-input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px #212121 inset;
    }
    .state-zip-row .MuiSelect-select {
      border-color: #dcdee2;
      background-color: #212121;
      color: #eaebeb;
      &.Mui-error {
        border: solid 1px red;
      }
    }
    .surcharge-totals > div {
      color: white;
    }
  }
  .invoice-error-wrapper {
    h1 {
      color: white;
    }
  }
  &.history-dialog-wrapper {
    h3 {
      color: white;
    }
    table th {
      color: white;
    }
  }
  .invoice-success-wrapper {
    h1 {
      color: white;
    }
    .invoice-information {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .invoicelist-container {
    background-color: lighten($color: #121212, $amount: 5);
  }

  .customer-payment-card {
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0, 0.2);
    background-color: lighten($color: #121212, $amount: 5);
  }

  .invoice-status-legend {
    background-color: lighten($color: #121212, $amount: 20);
  }
  .total-cell {
    background-color: $blue300;
  }
  //
  // Layout
  footer .footer {
    a,
    span {
      color: white;
    }
  }

  //
  // Components
  .MuiTabs-root.primary .MuiTab-root {
    color: rgba(0, 0, 0, 0.6);
    &.error {
      color: red;
      border-bottom: 2px solid red;
    }
  }
  .MuiTabs-root.secondary {
    .MuiButtonBase-root {
      color: $grey600;
      &.error {
        color: red;
        border-bottom: 2px solid red;
      }
    }
    .MuiTabs-indicator {
      background: $grey600;
    }
    .MuiButtonBase-root.Mui-selected {
      color: $dark-mode-blue;
      border-color: $dark-mode-blue;
    }
    .Mui-selected {
      //overrides selected tab turning blue rule
      &.error {
        color: red;
        border-bottom: 2px solid red;
      }
    }
  }
  .MuiRadio-root.Mui-checked {
    color: $dark-mode-blue;
  }
  .ach-form .MuiInputBase-root.text-input {
    color: white;
  }
  .tokenEx-card-wrapper .card-row {
    background: #212121;
    color: white;
    font-weight: 400;
  }
}
