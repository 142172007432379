.MuiTabs-root {
  .MuiTab-root {
    width: 50%;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: initial;
    font-size: 13px;
  }
}

.MuiTabs-root.primary {
  margin-bottom: 10px;
  .MuiTabs-flexContainer {
    // background: red;
    border-radius: 5px;
    padding: 2px;
    background: lightgray;
    .MuiButtonBase-root {
      border-radius: 5px;
      font-weight: bold;
      svg {
        display: none;
      }
      &.Mui-selected {
        background: #189ad0;
        color: white;
        svg {
          display: inline-block;
        }
      }
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
}

.MuiTabs-root.secondary {
  margin-bottom: 15px;

  .MuiButtonBase-root {
    color: $grey500;
    &.Mui-selected {
      color: $blue300;
      border-bottom: 2px solid $blue300;
      z-index: 2;
    }
  }

  .MuiTabs-indicator {
    background: $grey500;
    width: 100% !important;
    left: 0 !important;
  }
}
