@import '../abstracts/colors.module.scss';

:root {
  --branding-primary: #189ad0;
  --branding-secondary: #0a1a72;

  --branding-typography-primary: #fff;
  --branding-typography-secondary: #000;
}

.customer-payable-container {
  margin-top: 20px;

  .outstanding-amount {
    background: var(--branding-primary);
    color: var(--branding-typography-primary);
    font-weight: 600;
    padding: 10px 25px 10px 25px;
    margin-bottom: 5px;
    width: fit-content;
  }

  .payment-section {
    button.primary {
      background: var(--branding-primary);
      color: var(--branding-typography-primary);
    }
    background-color: color-mix(in srgb, var(--branding-primary) 20%, transparent);
  }
  .invoicelist-container {
    background-color: white;
    height: fit-content;
    overflow-x: scroll;
    padding: 10px;
    //   .MuiCardContent-root {
    border-top-color: var(--branding-secondary);
    border-top-style: solid;
    border-top-width: thick;
    box-shadow: 5px 5px 5px 5px rgb(0, 0, 0, 0.2);
    //   }

    .MuiTableRow-root {
      height: 70px;
      &.Mui-selected {
        background-color: inherit;
      }
    }
    .MuiTableCell-root {
      max-width: 120px;
      word-wrap: break-word;
    }
    // .disabled-row {
    //   .MuiTableCell-root {
    //     filter: opacity(25%);
    //     cursor: pointer;
    //   }
    // }
    .invoice-id-cell {
      white-space: nowrap;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .blue-text {
      color: $blue300;
    }

    .invoice-total-amounts {
      .MuiTableCell-root {
        font-weight: 600;
        &.surcharge-total {
          font-size: 18px;
        }
      }
    }
  }
  .invoice-status-legend {
    width: fit-content;
    background-color: #f3f3f3;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
  }

  .MuiTabs-root.primary .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
    background: var(--branding-primary);
    color: var(--branding-typography-primary);
  }

  .address-container {
    .MuiTabs-root.secondary .MuiButtonBase-root.Mui-selected {
      color: var(--branding-primary);
      border-bottom: 2px solid var(--branding-primary);
    }
    &.error {
      color: red;
      border-bottom: 2px solid red;
    }
  }
  thead {
    .MuiTableCell-root {
      .MuiSvgIcon-root {
        color: var(--branding-primary);
      }
    }
  }

  .invoicelist-container {
    .total-cell {
      text-align: center;
      background-color: color-mix(in srgb, var(--branding-primary) 20%, transparent);
      &.bold {
        font-weight: 600;
      }
    }
  }
}

.total-selected {
  font-weight: 600;
  text-align: center;
  background-color: var(--branding-secondary);
  color: var(--branding-typography-secondary);
  padding: 10px;
  justify-content: space-evenly;
  font-size: 18px;
  word-wrap: break-word;
  align-items: center;

  &.invoice {
    min-width: fit-content;
    max-width: 300px;
    margin-top: -16px;
    float: right;
    margin-right: 20px;
  }
}

.invoice-submit-modal {
  .MuiPaper-root {
    max-width: fit-content;
    padding: 15px;
    text-align: center;
  }
  .MuiTableCell-root {
    max-width: 150px;
    padding: 5px;
  }
  .submit-modal-header {
    text-wrap: nowrap;
    .MuiTableCell-root {
      font-weight: 600;
    }
  }
  .invoice-submit-total-row {
    padding: inherit;
  }
  .submit-modal-row {
    input {
      padding: inherit;
      padding-right: 10px;
    }
  }
  .invoice-total-amounts {
    .MuiTableCell-root {
      padding: 16px;
      font-weight: 600;
      &.surcharge-total {
        font-size: 18px;
      }
    }
  }

  .MuiButtonBase-root.MuiButton-contained {
    background: var(--branding-primary);
  }
  .MuiButtonBase-root.MuiButton-outlined {
    border-color: var(--branding-primary);
    color: var(--branding-primary);
  }
}

.invoice-submit-modal.dark-theme {
  .MuiPaper-root {
    .total-cell {
      background-color: #121212;
    }
  }
}

.header-cells {
  font-weight: 600;
}

.disabled-row {
  .MuiTableCell-root {
    filter: opacity(25%);
    cursor: pointer;
  }
}

.max-invoice-div {
  color: $warning_dark;
  border-style: solid;
  border-color: $warning_dark;
  padding: 10px;
}

.customer-payment-card {
  box-shadow: 5px 5px 5px 5px rgb(0, 0, 0, 0.2);
  padding: 20px;
  margin-top: 20px;
  border-top-style: solid;
  border-color: var(--branding-secondary);
  border-width: 5px;
  background-color: white;
  .MuiTableBody-root {
    .MuiTableRow-root:last-child {
      td {
        border-bottom-style: none;
      }
    }
  }
  .payment-confirmation-warning {
    color: $warning_invoice;
    border-color: $warning_invoice;
    border-style: solid;
    padding: 10px;
    max-width: 600px;
  }
}

.return-page-button {
  .MuiButtonBase-root {
    float: right;
    top: -20px;
    right: 20px;
    background-color: var(--branding-primary);
    color: var(--branding-typography-primary);
  }
}
.warning {
  color: $warning_invoice;
}
