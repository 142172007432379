$system_blue: #3c5978;
$system_white: #ffffff;

// from theme.js
$blue200: #66f3ff;
$blue300: #189ad0;
$blue400: #008fb3;
$blue450: #099ae9;
$blue500: #01579b;
$blue550: #1e74b6;
$blue600: #0b304c;
$blue800: #000624;

$lightblue200: #eafaff;
$lightblue300: #aee7ff;
$lightblue400: #76cbe7;
$lightblue500: #4fc3f7;
// yellow
$yellow100: #fcbc34;
$yellow200: #f5c71a;
$yellow300: #f5c71a;
$yellow400: #f5c71a;

// neutrals
$grey_blue800: #404854;
$grey500: #686868;
$grey600: #eaebeb;
$grey650: #666;
$grey_700: #616161;
$grey800: #353536;
$grey850: #212121;
$grey900: #1b1b1b;

// error
$error: #d32f2f;

// warning dark
$warning_dark: #ed6c02;

//warning invoice
$warning_invoice: #e53935;

// drawer
$drawer: #f4f5f5;

// header
$header_blue: #1a3640;

// footer
$footer_background: #eaeff1;

// login
$login_background: #3c5978;

//  headings
$dark-text-blue: #08304c;

$dark-mode-blue: $blue300;

$invoice-border-top: #0a1a72;

$placeholder-text: #babbbd;

:export {
  system-blue: $system_blue;
  system_white: $system_white;

  blue200: $blue200;
  blue300: $blue300;
  blue400: $blue400;
  blue450: $blue450;
  blue550: $blue550;
  blue500: $blue500;
  blue600: $blue600;
  blue800: $blue800;

  light_blue200: $lightblue200;
  light_blue300: $lightblue300;
  light_blue400: $lightblue400;
  light_blue500: $lightblue500;

  yellow100: $yellow100;
  yellow200: $yellow200;
  yellow300: $yellow300;
  yellow400: $yellow400;

  grey_blue800: $grey_blue800;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey_700;
  grey800: $grey800;
  grey850: $grey850;
  grey900: $grey900;

  error: $error;

  warning_dark: $warning_dark;
  placeholder_text: $placeholder-text;

  drawer: $drawer;
  header_blue: $header_blue;
  footer_background: $footer_background;
  login_background: $login_background;
}
