.error-container {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  text-align: center;

  p {
    font-weight: bold;
  }
  .error-information {
    margin-top: 60px;

    p {
      margin: 0px;
    }
  }
}
